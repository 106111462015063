
import { defineComponent } from "vue";
import CreateToken from "@/components/CreateToken.vue";
import ParticipantPage from "@/components/ParticipantPage.vue";
import { Pages } from "@/Pages";
import HelpPage from "@/components/HelpPage.vue";

export default defineComponent({
  name: "App",
  components: {
    HelpPage,
    ParticipantPage,
    CreateToken,
  },
  data() {
    return {
      hash: window.location.hash.substring(1), // remove the #
      page: Pages.Root,
    };
  },
  computed: {
    Pages() {
      return Pages;
    },
  },
  mounted() {
    if (this.hash) {
      this.page = Pages.Participant;
    }
  },
  methods: {
    navigate(page: Pages) {
      if (page == Pages.Root) {
        window.location.href = window.location.href.split("#")[0];
      }
      this.page = page;
    },
  },
});
