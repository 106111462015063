import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantPage = _resolveComponent("ParticipantPage")!
  const _component_CreateToken = _resolveComponent("CreateToken")!
  const _component_HelpPage = _resolveComponent("HelpPage")!

  return (_ctx.page == _ctx.Pages.Participant)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ParticipantPage, {
          token: _ctx.hash,
          onNavigate: _ctx.navigate
        }, null, 8, ["token", "onNavigate"])
      ]))
    : (_ctx.page == _ctx.Pages.CreateToken || _ctx.page == _ctx.Pages.Root)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CreateToken, { onNavigate: _ctx.navigate }, null, 8, ["onNavigate"])
        ]))
      : (_ctx.page == _ctx.Pages.Help)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_HelpPage, { onNavigate: _ctx.navigate }, null, 8, ["onNavigate"])
          ]))
        : _createCommentVNode("", true)
}