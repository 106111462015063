import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { Config } from "@/config";

/* eslint-disable @typescript-eslint/no-explicit-any */
const app = createApp(App);
if (!("config" in (window as any))) {
  (window as any).config = {
    AppProtocol: "contextaware",
    ServerName: "IMPERIO",
    ApiRootURL: "https://backend.imperio.dynamicdashboard.ilabt.imec.be/v2_1/",
    CCEmails: [],
  };
}

app.provide<Config>("config", (window as any).config as Config);

app.mount("#app");
